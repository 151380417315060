<template>
  <div>
    <van-empty v-if="list.length == 0" description="暂无报告记录" />
    <div class="listbox" v-for="(item, index) in list" :key="index">
      <div class="lbox_l">
        <p>个人报告</p>
        <p>姓名:{{ item.username }}</p>
        <p>手机号:{{ item.mobile }}</p>
        <p>{{ item.createTime }}</p>
      </div>
      <div class="lbox_r">
        <van-button
          type="primary"
          v-if="item.orderStatus == 2"
          @click="viewReport(item.orderNo, item.product)"
          >查看报告</van-button
        >
        <van-button
          type="warning"
          v-if="item.orderStatus == 1"
          @click="topay(item)"
          >去支付</van-button
        >
        <van-button type="danger" v-if="item.orderStatus == 3"
          >已退款</van-button
        >
      </div>
    </div>

    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <p class="title">
            输入信息查询 <van-icon @click="cancel" name="cross" />
          </p>
          <p class="ipt">
            <input
              type="text"
              v-model="searchValue"
              maxlength="18"
              name=""
              id=""
              placeholder="请输入身份证号或手机号"
            />
          </p>
          <p class="btn"><button @click="queryNow">立即查询</button></p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { Overlay, Empty, Toast } from 'vant';

Vue.use(Overlay);
Vue.use(Empty);

import { myReport } from '../api/getUserInfo';
export default {
  name: 'Reportquery',
  data() {
    return {
      listShow: false,
      show: true,
      searchValue: '',
      list: [],
      cid: this.$route.query.cid ? this.$route.query.cid : '',
    };
  },
  methods: {
    cancel() {
      this.show = false;
    },
    queryNow() {
      if (!this.searchValue) return Toast('请输入身份证号或手机号');
      Toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      myReport({ searchValue: this.searchValue })
        .then((res) => {
          if (res.data.code == 200) {
            if (res.data.data.length > 0) {
              this.list = res.data.data;
              this.show = false;
              Toast.clear();
            } else {
              Toast('暂无报告记录');
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    },
    viewReport(orderNo, product) {
      if (orderNo) {
        // 1 是百融
        if (product == 1) {
          this.$router.push({
            path: '/success',
            query: { orderNo: orderNo, cid: this.cid },
          });
        } else {
          // 2 是天创
          this.$router.push({
            path: '/prosperity',
            query: { orderNo: orderNo, cid: this.cid },
          });
        }
      } else {
        Toast('报告不存在');
      }
    },
    topay(item) {
      if (!item) return Toast('订单不存在');
      this.$router.push({
        path: '/toBePaid',
        query: {
          userId: item.userId,
          cid: item.cid,
          idCard: item.idCard,
          userName: item.username,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.listbox {
  width: 92%;
  height: 250px;
  background: #3f4052;
  border-radius: 25px;
  margin: 50px auto;
  color: #fff;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .lbox_l {
    p:nth-child(1) {
      font-weight: 600;
      font-size: 32px;
      margin-bottom: 10px;
    }
    p:nth-child(2) {
      margin-bottom: 5px;
      font-size: 26px;
    }
    p:nth-child(3) {
      margin-bottom: 20px;
      font-size: 26px;
    }
    p:nth-child(4) {
      margin-bottom: 10px;
    }
  }
  .lbox_r {
    display: flex;
    align-items: flex-end;
    button {
      width: 160px;
      height: 60px;
      // background: #07c160;
      outline: none;
      border: none;
      color: #fff;
      border-radius: 5px;
      font-size: 24px;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  height: 360px;
  background-color: #fff;
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
}
.title {
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 32px;
  .van-icon {
    float: right;
    font-size: 36px;
    color: #777;
  }
}
.ipt {
  width: 88%;
  height: 70px;
  margin: 50px auto;
  input {
    width: 100%;
    height: 70px;
    border: none;
    background: #f5f5f5;
    text-indent: 2em;
    font-size: 28px;
  }
}
.btn {
  width: 88%;
  height: 88px;
  margin: 0 auto;
  button {
    width: 100%;
    height: 88px;
    background: #1989fa;
    color: #fff;
    font-size: 28px;
    border-radius: 50px;
    outline: none;
    border: none;
  }
}
</style>
